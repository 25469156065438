var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pa-6"},[_c('v-flex',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-icon',{attrs:{"color":_vm.theme}},[_vm._v("local_library")]),_c('v-subheader',{staticClass:"text-h6"},[_c('h4',[_vm._v("JADWAL PESERTA UJIAN")])])],1),_c('v-flex',{staticClass:"pb-5 pl-7"},[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Daftar Seluruh Jadwal Pesert Ujian Yang Tersedia Yang Tersedia ")])]),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.theme + " lighten-1"},[_c('v-tooltip',{attrs:{"color":_vm.theme + " draken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.postSendPublicMessage}},[_vm._v("mail")])],1)]}}])},[_c('span',[_vm._v("KIRIM PESAN MASAL")])]),_c('v-tooltip',{attrs:{"color":_vm.theme + " draken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.postConfirmDialogReset}},[_vm._v("published_with_changes")])],1)]}}])},[_c('span',[_vm._v("RESET JADWAL UJIAN")])]),_c('v-tooltip',{attrs:{"color":_vm.theme + " draken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.postGenerate}},[_vm._v("low_priority")])],1)]}}])},[_c('span',[_vm._v("GENERATE JADWAL UJIAN")])]),_c('v-tooltip',{attrs:{"color":_vm.theme,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.fetchRecords}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("REFRESH")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","color":_vm.theme},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',{staticClass:"pt-5 pl-3 pr-5"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Tanggal","color":_vm.theme,"dense":"","outlined":"","type":"date"},on:{"change":_vm.fetchRecords},model:{value:(_vm.tanggal),callback:function ($$v) {_vm.tanggal=$$v},expression:"tanggal"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Sesi","color":_vm.theme,"dense":"","outlined":"","items":_vm.sesis},on:{"change":_vm.fetchRecords},model:{value:(_vm.jadwal_ujian_sesi_id),callback:function ($$v) {_vm.jadwal_ujian_sesi_id=$$v},expression:"jadwal_ujian_sesi_id"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.download",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.postDownload(value)}}},[_vm._v("cloud_download")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus Data")])])]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value ? "green" : "red"}},[_vm._v(_vm._s(value ? "Wajib" : "Opsional"))])]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.postSendPrivateMessage(value)}}},[_vm._v("mail")])],1)]}}],null,true)},[_c('span',[_vm._v("Kirim Pesan Pribadi")])]),_c('v-tooltip',{attrs:{"color":"red","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.postDeleteRecord(value)}}},[_vm._v("published_with_changes")])],1)]}}],null,true)},[_c('span',[_vm._v("Reset Ujian")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"300px"},model:{value:(_vm.dialog.reset),callback:function ($$v) {_vm.$set(_vm.dialog, "reset", $$v)},expression:"dialog.reset"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline red white--text",attrs:{"light":""}},[_c('v-icon',{staticClass:"pr-3",attrs:{"color":"white"}},[_vm._v("report_problem")]),_c('span',[_vm._v("Konfirmasi")])],1),_c('v-card-text',[_c('p',{staticClass:"pt-5"},[_vm._v(" Apakah anda yakin akan melakukan reset jadwal ujian ...? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.theme,"text":""},on:{"click":function($event){_vm.dialog.reset = false}}},[_vm._v("Tidak")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.postReset}},[_vm._v("Ya, Saya Yakin")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }