var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pa-6"},[_c('v-flex',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-icon',[_vm._v("description")]),_c('v-subheader',{staticClass:"text-h6"},[_c('h4',[_vm._v("MANAJEMEN MASTER SOAL")])])],1),_c('v-flex',{staticClass:"pb-5 pl-7"},[_c('div',{staticClass:"grey--text mb-2"},[_vm._v("Daftar Seluruh Soal Yang Tersedia")])]),_c('v-row',[_c('v-col',{attrs:{"col":"8"}},[_c('v-card',[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.theme},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fetchRecords}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("add_circle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fetchRecords}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("autorenew")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","color":_vm.theme},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"blue","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.openButirSoal(value)}}},[_vm._v("drag_indicator")])],1)]}}],null,true)},[_c('span',[_vm._v("Majemen Butir Soal")])]),_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.previewSoal(value)}}},[_vm._v("preview")])],1)]}}],null,true)},[_c('span',[_vm._v("Pratinjau Soal")])]),_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editRecord(value)}}},[_vm._v("create")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Data")])]),_c('v-tooltip',{attrs:{"color":"red","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.postConfirmDelete(value)}}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus Data")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1),_c('v-col',{attrs:{"col":"4"}},[_c('v-card',[_c('v-card-title',{class:_vm.theme},[_vm._v(" Formulir Master Soal ")]),_c('v-card-subtitle',{class:_vm.theme},[_vm._v(" Formulir untuk mendaftarkan/merubah Master Soal ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":_vm.device.mobile}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Mata Pelajaran","color":_vm.theme,"hide-details":"device.desktop","autocomplete":"off"},model:{value:(_vm.record.mapel),callback:function ($$v) {_vm.$set(_vm.record, "mapel", $$v)},expression:"record.mapel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Keterangan","color":_vm.theme,"hide-details":"","rows":"5"},model:{value:(_vm.record.keterangan),callback:function ($$v) {_vm.$set(_vm.record, "keterangan", $$v)},expression:"record.keterangan"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"flex flex-row-reverse"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.event.update),expression:"!event.update"}],attrs:{"outlined":"","color":_vm.theme},on:{"click":_vm.postAddNewRecord}},[_vm._v("TAMBAH")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.event.update),expression:"event.update"}],attrs:{"outlined":"","color":_vm.theme},on:{"click":_vm.postUpdateRecord}},[_vm._v("UBAH")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }