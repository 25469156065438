var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pa-6"},[_c('v-flex',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-icon',{attrs:{"color":_vm.theme}},[_vm._v("verified")]),_c('v-subheader',{staticClass:"text-h6"},[_c('h4',[_vm._v("DATA TELAH TERVERIFIKASI")])])],1),_c('v-flex',{staticClass:"pb-5 pl-7"},[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Daftar Seluruh Terverifikasi Yang Tersedia ")])]),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.theme + " lighten-1"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fetchRecords}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("autorenew")])],1),_c('v-tooltip',{attrs:{"color":_vm.theme,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.openFormImport}},[_vm._v("mdi-cloud-upload")])],1)]}}])},[_c('span',[_vm._v("Import Data Peserta")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","color":_vm.theme},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value == '4' ? 'green' : 'red'}},[_vm._v(_vm._s(value == 4 ? "BERKAS DITERIMA" : value == 5 ? "BERKAS DITOLAK" : "BERKAS DICABUT")+" ")])]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.openFormVerifikasi(value)}}},[_vm._v("verified_user")])],1)]}}],null,true)},[_c('span',[_vm._v("Verifikasi Ulang Peserta")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1),_c('v-col',{attrs:{"col":"12"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.device.desktop ? "600px" : "100%","persistent":""},model:{value:(_vm.formimport.show),callback:function ($$v) {_vm.$set(_vm.formimport, "show", $$v)},expression:"formimport.show"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo white--text"},[_c('v-icon',{staticClass:"mr-1 white--text",attrs:{"small":""}},[_vm._v("mdi-circle")]),_vm._v(" Formulir Import Data Peserta ")],1),_c('v-card-title',{staticClass:"justify-center grey--text"}),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"File  XLS, XLSX (Max: 2 MB)","append-outer-icon":"attachment","outlined":"","dense":"","hide-details":"","color":_vm.theme.color},on:{"click:append-outer":_vm.uploadFile},model:{value:(_vm.formimport.path),callback:function ($$v) {_vm.$set(_vm.formimport, "path", $$v)},expression:"formimport.path"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":_vm.theme.color},on:{"click":_vm.postImport}},[_vm._v("Upload")]),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){return _vm.closeFormImport()}}},[_vm._v("Batal")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }