var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pa-6"},[_c('v-flex',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-icon',[_vm._v("description")]),_c('v-subheader',{staticClass:"text-h6"},[_c('h4',[_vm._v("MANAJEMEN PARAMETER PRESTASI")])])],1),_c('v-flex',{staticClass:"pb-5 pl-7"},[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Daftar Seluruh Parameter Prestasi Yang Tersedia ")])]),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.theme},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fetchRecords}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("autorenew")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","color":_vm.theme},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editRecord(value)}}},[_vm._v("create")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Data")])])]}},{key:"item.is_active",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(value),expression:"value"}],attrs:{"color":"green"}},[_vm._v("check_circle")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!value),expression:"!value"}],attrs:{"color":"red"}},[_vm._v("highlight_off")])]}}],null,true)},[_c('span',[_vm._v("Edit Data")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1),_c('v-col',{attrs:{"col":"12"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.device.desktop ? "600px" : "100%","persistent":""},model:{value:(_vm.form.edit),callback:function ($$v) {_vm.$set(_vm.form, "edit", $$v)},expression:"form.edit"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.theme,"dark":""}},[_vm._v("Formulir Parameter Nilai")]),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("DATA PARAMETER")]),_c('v-card-text',[_c('v-col',{attrs:{"col":"12"}},[_c('v-text-field',{attrs:{"outlined":"","color":_vm.theme,"hide-details":_vm.device.desktop,"label":"PRESTASI","dense":"","disabled":""},model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","color":_vm.theme,"hide-details":_vm.device.desktop,"label":"SKOR","dense":""},model:{value:(_vm.record.skor),callback:function ($$v) {_vm.$set(_vm.record, "skor", $$v)},expression:"record.skor"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":_vm.theme},on:{"click":_vm.postUpdateRecord}},[_vm._v("Simpan")]),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.form.edit = false}}},[_vm._v("Batal")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }