var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"cols":_vm.device.desktop ? "6" :"12"}},[_c('v-row',{staticClass:"justify-center"},[_c('img',{attrs:{"src":require("../../../assets/images/Pagu.png"),"alt":"","srcset":"","width":"60%","height":"90%"}})])],1),_c('v-col',{attrs:{"cols":_vm.device.desktop ? "6" :"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Program Keahlian")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.info.showpagu ? _vm.headers: _vm.headers2,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":value == 'Pengajuan'
                      ? 'grey'
                      : value == 'Perbaikan Berkas'
                      ? 'yellow'
                      : value == 'Pengajuan Perbaikan'
                      ? 'yellow'
                      : value == 'Berkas Diterima'
                      ? 'green'
                      : 'red'}},[_vm._v(_vm._s(value))])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }