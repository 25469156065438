<template>
  <PengumumanComponentVue />
</template>

<script>
import PengumumanComponentVue from "./components/PengumumanComponent.vue";
export default {
  name: "Pengumuman",
  components: {
    PengumumanComponentVue,
  },
  data: () => ({}),
};
</script>

<style>
</style>