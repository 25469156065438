<template>
  <login-component-vue />
</template>

<script>
import LoginComponentVue from "./components/LoginComponent.vue";
export default {
  name: "login",
  components: {
    LoginComponentVue,
  },
};
</script>

<style>
</style>