var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pa-6"},[_c('v-flex',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-icon',[_vm._v("mdi-map")]),_c('v-subheader',{staticClass:"text-h6"},[_c('h4',[_vm._v("MANAJEMEN REFERENSI KECAMATAN")])])],1),_c('v-flex',{staticClass:"pb-5 pl-7"},[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Daftar Seluruh Referensi Kecamatan Tersedia ")])]),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{attrs:{"color":_vm.theme}},[_c('v-card-title',{staticClass:"flex flex-row-reverse"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name:'master-kebupaten',params:{province_id: _vm.$route.params.province_id}})}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close-circle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fetchRecords}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("autorenew")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.addNewRecord}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus-circle")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","color":_vm.theme},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.aksi",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.editRecord(value)}}},[_vm._v("mdi-map")])],1)]}}],null,true)},[_c('span',[_vm._v("Manajemen Desa")])]),_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editRecord(value)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Kecamatan")])]),_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.postDeleteRecord(value)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus Data")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1),_c('v-col',{attrs:{"col":"12"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.device.desktop ? "600px" : "100%","persistent":""},model:{value:(_vm.add),callback:function ($$v) {_vm.add=$$v},expression:"add"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.theme,"dark":""}},[_vm._v("Formulir REFERENSI SEKOLAH")]),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("DATA SEKOLAH")]),_c('v-card-text',[_c('v-col',{attrs:{"col":"12"}},[_c('v-text-field',{attrs:{"outlined":"","color":_vm.theme,"hide-details":_vm.device.desktop,"label":"KODE","dense":"","maxLength":"8"},model:{value:(_vm.record.id),callback:function ($$v) {_vm.$set(_vm.record, "id", $$v)},expression:"record.id"}})],1),_c('v-col',{attrs:{"col":"12"}},[_c('v-text-field',{attrs:{"outlined":"","color":_vm.theme,"hide-details":_vm.device.desktop,"label":"KECAMATAN","dense":""},model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edit),expression:"!edit"}],attrs:{"outlined":"","color":_vm.theme},on:{"click":_vm.postAddNewRecord}},[_vm._v("Simpan")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.edit),expression:"edit"}],attrs:{"outlined":"","color":_vm.theme},on:{"click":_vm.postUpdateRecord}},[_vm._v("Simpan")]),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.add = false}}},[_vm._v("Batal")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }