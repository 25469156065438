<template>
  <div>
    <RegistrasiComponentVue />
  </div>
</template>

<script>
import RegistrasiComponentVue from "./components/RegistrasiComponent.vue";
export default {
  name: "registrasi",
  components: {
    RegistrasiComponentVue,
  },
};
</script>

<style>
</style>