var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"cols":_vm.device.desktop ? "6" :"12"}},[_c('v-row',{staticClass:"justify-center"},[_c('img',{attrs:{"src":require("../../../assets/images/DaftarPeserta.png"),"alt":"","srcset":"","width":"60%","height":"90%"}})])],1),_c('v-col',{attrs:{"cols":_vm.device.desktop ? "6" :"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Daftar Peserta")]),_vm._v(" Berikut adalah peserta yang telah melakukan pendaftaran di "+_vm._s(_vm.info.company)+" , berikut kami tampilkan status tahapan yang telah dilalui oleh peserta PPDB "),_c('br'),_c('br'),_vm._v(" Berikut adalah pengertian dari setiap tahapan status: "),_c('ul',[_c('li',[_vm._v(" Pengajuan : Berkas pengajuan sudah masuk ")]),_c('li',[_vm._v(" Perbaikan Berkas : Pengajuan berkas harus diperbaiki ")]),_c('li',[_vm._v(" Pengajuan perbaikan : Pengajuan berkas perbaikan ")]),_c('li',[_vm._v(" Berkas Diterima : Pengajuan Berkas diterima & Terverifikasi ")]),_c('li',[_vm._v("Cabut Berkas : Berkas dicabut dari pendaftaran")])])])],1),_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-end"},[_c('p',{staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v("Data per tanggal : "+_vm._s(_vm.timestamps))])]),_c('v-row',{staticClass:"justify-end"},[_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v("Data ini di perbaharui per 5 menit")])])],1),_c('v-row',{staticClass:"mb-5"},[_c('v-tooltip',{attrs:{"color":_vm.theme + " darken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.fetchPendaftars}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Data")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.device.desktop ? "4" : "10"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","color":_vm.theme,"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":value == 'Pengajuan'
                      ? 'grey'
                      : value == 'Perbaikan Berkas'
                      ? 'yellow'
                      : value == 'Pengajuan Perbaikan'
                      ? 'yellow'
                      : value == 'Berkas Diterima'
                      ? 'green'
                      : 'red'}},[_vm._v(_vm._s(value))])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }