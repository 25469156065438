var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pa-6"},[_c('v-flex',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-icon',{attrs:{"color":_vm.theme}},[_vm._v("work")]),_c('v-subheader',{staticClass:"text-h6"},[_c('h4',[_vm._v("KELOLA BUTIR SOAL")])])],1),_c('v-flex',{staticClass:"pb-5 pl-7"},[_c('div',{staticClass:"grey--text mb-2"},[_vm._v("Daftar Seluruh Butir Soal Yang Tersedia")])]),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"flex flex-row-reverse blue darken-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'ujian-master-soal' })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("highlight_off")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.openAddNew}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("add_circle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.fetchRecords(this.record)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("autorenew")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.soal",fn:function(ref){
var value = ref.value;
return [_c('p',{domProps:{"innerHTML":_vm._s(value)}})]}},{key:"item.jawaban",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value == 'A' ? 'green' : 'grey'}},[_vm._v("A")]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value == 'B' ? 'green' : 'grey'}},[_vm._v("B")]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value == 'C' ? 'green' : 'grey'}},[_vm._v("C")]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value == 'D' ? 'green' : 'grey'}},[_vm._v("D")]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value == 'E' ? 'green' : 'grey'}},[_vm._v("E")])]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"small":"","color":value == true ? 'green' : 'red'}},[_vm._v("calendar_today")])]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"orange","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editRecord(value)}}},[_vm._v("create")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Data")])]),_c('v-tooltip',{attrs:{"color":"red","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.postDeleteRecord(value)}}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus Data")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }