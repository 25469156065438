<template>
  <verify-component-vue />
</template>

<script>
import VerifyComponentVue from "./components/VerifyComponent.vue";
export default {
  name: "Verifikasi",
  components: {
    VerifyComponentVue,
  },
  data: () => ({}),
};
</script>

<style>
</style>